<template>
    <li class="admin-plus-users">
        <div class="admin-plus-users__image">
            <picture>
                <img v-if="user.photo1" :src="user.photo1" alt="">
                <img v-else src="../../assets/images/ludzik1.png" alt="">
            </picture>
        </div>
        <div class="admin-plus-users__name">
            <p>
                {{ user.questionnaire[0].name }}, 
                {{ setAge(user.questionnaire[0].age) }}
                <span v-if="user.questionnaire[0].matchSw?.length > 0">
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" /><path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" /><path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" /><path d="M8.56 20.31a9 9 0 0 0 3.44 .69" /><path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" /><path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" /><path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" /><path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" /><path d="M9 12l2 2l4 -4" /></svg>
                </span>
            </p>
            <router-link target="_blank" :to="'/admin/plus/verify/' + user._id" @click="setPrevLink()">ZOBACZ PROFIL</router-link>
        </div>
    </li>
</template>
<script>
    export default {
        data() {
            return {
                
            }
        },
        props: {
            user: Object
        },
        methods: {
            setAge(el) {
                const dzisiaj = new Date();
                const dataUrodzeniaDate = new Date(el);
                
                let wiek = dzisiaj.getFullYear() - dataUrodzeniaDate.getFullYear();
                
                const miesiacDzisiaj = dzisiaj.getMonth();
                const dzienDzisiaj = dzisiaj.getDate();
                const miesiacUrodzenia = dataUrodzeniaDate.getMonth();
                const dzienUrodzenia = dataUrodzeniaDate.getDate();
                
                if (miesiacDzisiaj < miesiacUrodzenia || (miesiacDzisiaj === miesiacUrodzenia && dzienDzisiaj < dzienUrodzenia)) {
                    wiek--;
                }
                
                return wiek;
            },
            setPrevLink() {
                window.localStorage.setItem('adminPlusPrev', location.pathname)
            }
        },
    }
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/global.scss";

    .admin-plus-users {
        border: 1px solid $black;
        &__image {
            picture {
                display: block;
                img {
                    display: block;
                    width: 100%;
                    object-fit: cover;
                    aspect-ratio: 1;
                    border-bottom: 1px solid $black;
                }
            }
        }
        &__name {
            position: relative;
            p {
                text-align: center;
                padding: 37px 0 60px;
                font-family: 'Open Sans', sans-serif;
                font-size: 1.6rem;
                line-height: 2rem;
                color: $black;
                font-weight: 700;
                text-transform: uppercase;
                @include breakpoint-max("m-large") {
                    padding: 15px 0 40px;
                }
                @include breakpoint-max("mobile") {
                    padding: 8px 0 25px;
            }
            }
            a {
                text-decoration: none;
                position: absolute;
                bottom: -26px;
                left: 50%;
                transform: translateX(-50%);
                background-color: $pink;
                padding: 15px 0;
                width: 75%;
                display: grid;
                place-items: center;
                text-transform: uppercase;
                font-family: 'Open Sans', sans-serif;
                font-size: 1.6rem;
                line-height: 2rem;
                color: $white;
                font-weight: 700;
                @include breakpoint-max("m-large") {
                    padding: 9px 0;
                    bottom: -19px;
                }
                @include breakpoint-max("mobile") {
                    font-size: 1rem;
                    bottom: -11px;
                    padding: 4px 0;
                    width: 80px;
                }
            }
        }
    }
    
</style>